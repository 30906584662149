/**
 * Progressbar
 */
import ProgressBar from "progressbar.js";
window.ProgressBar = ProgressBar;

/**
 * Lang
 */
let locale = document.getElementsByTagName("html")[0].getAttribute("lang");

function createProgressCircle(
    containerId,
    topText,
    middleText,
    bottomText,
    middleValue,
    textColor = "#3c413f",
    trailColor = "#7abb9f",
    progressColor = "#25a075"
) {
    var strokeSize = 5;

    var textOptions = {
        autoStyleContainer: false,
        style: {
            color: textColor,
            lineHeight: "1.3rem",
            fontSize: "0.875rem",
            fontWeight: 600,
            fontFamily: "Qanelas",
            position: "absolute",
            width: "100%",
            left: "50%",
            top: "50%",
            padding: 0,
            margin: 0,
            transform: {
                prefix: true,
                value: "translate(-50%, -50%)",
            },
        },
    };

    let circle = new ProgressBar.Circle(containerId, {
        color: progressColor,
        trailColor: trailColor,
        strokeWidth: strokeSize,
        trailWidth: strokeSize,
        easing: "easeInOut",
        duration: 1400,
        text: textOptions,
        from: { color: trailColor, width: strokeSize },
        to: { color: progressColor, width: strokeSize },
        step: function (state, circle) {
            if (Math.round(circle.middleValue) > 0) {
                middleValue = Math.round(circle.middleValue);
            }
            circle.path.setAttribute("stroke-width", state.width);
            circle.setText(
                setProgressCirlceText(
                    topText,
                    middleValue + middleText,
                    bottomText
                )
            );
        },
    });

    return circle;
}

function setProgressCirlceText(topText, middleText, bottomText) {
    let check_for_integer = parseInt(middleText);
    if (check_for_integer == middleText) {
        middleText = new Intl.NumberFormat(locale, {
            maximumFractionDigits: 0,
        }).format(middleText);
    }

    return (
        "<p class='md:text-[14px] text-xs font-medium'>" +
        topText +
        "</p>" +
        "<p class='text-[16px] leading-none md:mt-0 md:px-1 font-bold md:text-[23px]'>" +
        middleText +
        "</p>" +
        "<p class='md:text-[14px] text-xs font-medium'>" +
        bottomText +
        "</p>"
    );
}

export { createProgressCircle, setProgressCirlceText };
